<template>
  <div class="allorder-box">
    <div class="top">
      <img src="../../../static/image/left.png" @click="back" alt="" />
      <div class="order_text">确认订单</div>
    </div>

    <div class="box">
      <div class="allorder" @click="nana">寄卖订单</div>
      <div class="purchase" @click="lyn">购买订单</div>
    </div>

    <div class="allorder_box">
      <div>已完成</div>
      <div>寄卖中</div>
      <div>已取消</div>
    </div>

    <div class="purchase_box" v-show="show">
      <div>已完成</div>
      <div>待支付</div>
      <div>已取消</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 1,
      show: false,
    };
  },
  methods: {
    lyn() {
      this.show = true;
    },
    back() {
      this.$router.push("/user");
    },
  },
};
</script>

<style lang="less" scoped>
.allorder_box {
  display: flex;
  justify-content: space-around;
  color: #999999;
  font-size: 13px;
  height: 58px;
  line-height: 58px;
}
.purchase_box {
  display: flex;
  justify-content: space-around;
  color: #999999;
  font-size: 13px;
  height: 58px;
  line-height: 58px;
}
.box {
  display: flex;
  justify-content: space-around;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  line-height: 20px;
  line-height: 42px;
  height: 42px;
  .allorder {
    border-bottom: 3px solid #000000;
    padding-left: 30px;
    padding-right: 30px;
  }

  .purchase {
    color: #999999;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.allorder-box {
  background: #f3f4f8;
}

.top {
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  img {
    width: 11px;
    height: 20px;
  }
  .order_text {
    font-size: 18px;
    margin-left: 38%;
  }
}
</style>
